<template>
  <v-card fluid flat tile height="140vh" class="back mb-n15 pa-6 px-md-15">
    <v-row>
      <v-col cols md="12">
        <v-card>
          <v-card-title>Dial from your desktop
            <v-chip class="ma-2" color="green" text-color="white">
              BETA
            </v-chip>
          </v-card-title>
          <v-card-text>Launch calls from your browser using Skype for Business, GoDial Telecmi Cloud Calling or any
            Calling app which can become
            default call handler for your computer.
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" class="text-center">
        <Dialer ref="dialer" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Dialer from "../components/dialer.vue";
export default {
  components: { Dialer },



  methods: {

  },
};
</script>

<style>

</style>
