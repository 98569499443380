import axios from 'axios';
import store from '../store/index';
var dispose = {};
dispose.setDisposition = async function(callItem, nextCall) {	
	return await axios.post(`${store.state.ENDPOINT}/contacts/dispose`, {
		contact: callItem,
		type: "call",
		returnNextCall: nextCall,
		listId: store.state.selectedList,
		dialerAction: "place_call",
		v: 3,
	},{
		headers: {
			'Authorization': `${store.state.user.token}` 
		}
	});
};
export default dispose;