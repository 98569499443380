<template>
  <div class="wrapper d-flex justify-center">
    <div class="mobile">
      <v-img src="../assets/mobile.png" height="530" contain></v-img>
    </div>
    <v-card flat tile dark class="content">
      <v-card-title class="pb-0">
        <h4 ref="dialer_text" class="white--text dialer--text">Dialer</h4>
        <h4 ref="dispo_text" class="white--text dispo--text">Disposition</h4>
        <!-- <v-spacer></v-spacer>
						<v-btn icon small dark @click="show=false" v-if="!hideCloseButton">
								<v-icon>mdi-close-circle</v-icon>
						</v-btn> -->
      </v-card-title>
      <v-card-text ref="dialer" class="align-center dialer">
        <v-autocomplete
          v-model="selectedList"
          class="pt-1"
          :items="lists"
          item-text="name"
          item-value="id"
          label="Choose List"
          autocomplete="false"
          prepend-inner-icon="view_list"
          hide-details
          dense
          outlined
          flat
        />
        <v-card v-if="callItem.phone" class="primarygrad mb-5 rounded">
          <div class="my-5 mx-1 pa-5 pt-0 pb-0 mb-0">
            <v-card-title>
              <v-icon dark size="72" class="mx-auto">mdi-account-circle</v-icon>
            </v-card-title>
            <v-card-text class="pa-0 grey--text text--darken-3">
              <h2 class="text-center">{{ callItem.name }}</h2>
              <p class="text-center">{{ callItem.phone }}</p>
            </v-card-text>
          </div>
          <v-card-actions class="d-flex ">
            <v-chip small class="pointer"> Next Call in: {{ timer }}s </v-chip>
            <v-spacer></v-spacer>
            <v-chip small class="pointer" @click="skipCall()">
              Skip
              <v-icon size="20">mdi-chevron-double-right</v-icon>
            </v-chip>
          </v-card-actions>
        </v-card>
        <v-card v-else class="primarygrad mb-5 rounded">
          <div class="my-5 mx-1 pa-5 pt-0 pb-0 mb-0">
            <v-card-title>
              <v-icon dark size="72" class="mx-auto">mdi-account-circle</v-icon>
            </v-card-title>
            <v-card-text class="pa-0 grey--text text--darken-3">
              <p class="text-center">
                No more NEW contacts available in this list, please rechurn or
                add more contacts
              </p>
            </v-card-text>
          </div>
        </v-card>

        <v-btn
          v-if="callItem.phone"
          block
          dark
          :class="dialState ? 'red' : 'primarygrad'"
          @click="startCalling()"
        >
          <b>{{ dialState ? "STOP" : "START" }}</b>
        </v-btn>

        <v-card v-if="callItem.phone" light class="primarygrad mt-5">
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header class="primarygrad">
                <template v-slot:default="{ open }">
                  <v-row>
                    <v-icon>mdi-clock-outline</v-icon>
                    <h4 class="font-weight-medium mt-1 ml-3">Countdown</h4>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="8">Waiting time before next call</v-col>
                  <v-col cols="4" class="d-flex justify-center align-center">
                    <v-btn icon @click="setTimer(false)">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h3>{{ countDownTimer }}s</h3>
                    <v-btn icon @click="setTimer(true)">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="primarygrad">
                <v-row>
                  <v-icon>mdi-dialpad</v-icon>
                  <h4 class="mb-0 font-weight-medium mt-1 ml-3">Dialer</h4>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="noPadAccordion">
                <v-list>
                  <v-radio-group
                    v-model="selectedDialer"
                    @change="dialerChanged"
                  >
                    <template v-for="(item, index) in dialerLists">
                      <v-list-item :key="index">
                        <v-list-item-avatar>
                          <v-img :src="item.logo"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-html="item.name"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-radio :value="`${item.name}`"></v-radio>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-radio-group>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-card-text>
      <v-card-text ref="disposition" class="align-center absolute disposition">
        <v-card flat light class="mb-5 rounded transparent hAmin">
          <v-list class="transparent px-2">
            <v-list-item
              v-for="dis in dispoList"
              :key="dis.id"
              class="rounded my-2 font-weight-medium"
              :style="`background:${dis.hex}`"
              @click="setDispo(dis)"
            >
              {{ dis.title }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import disposer from "../js/disposer";
import moment from "moment-timezone";
export default {
  data: () => ({
    show: false,
    callItem: {},
    nextCallItem: {},
    timer: 5,
    nextCallPresent: true,
    dialState: false,
    waiting: "",
    countDown: "",
    callStartTime: "",
    callEndTime: "",
    lists: [],
    dialerLists: [
      {
        name: "Default Dialer",
        logo: require("../assets/dialers/dialer.png"),
        // href: 'tel:${this.callItem.phone}'
      },
      {
        name: "Skype",
        logo: require("../assets/dialers/skype.png"),
        // href: 'skype://${this.callItem.phone}?call'
      },
    ],
    selectedDialer: "Default Dialer",
    hideCloseButton: false,
  }),

  computed: {
    ...mapGetters(["ENDPOINT", "teams", "selectedTeam"]),
    dispoList() {
      var temp = [...this.$store.getters.dispoList];
      var exclu = ["NEW", "LINK", "SMS", "SKIP", "EMAIL", "APP"];
      var dispo = temp.filter((d) => {
        return !exclu.includes(d.title);
      });
      return dispo;
    },
    selectedList: {
      get() {
        return this.$store.getters.selectedList;
      },
      set(v) {
        this.$store.commit("setSelectedList", v);
        this.nextCall();
      },
    },

    countDownTimer: {
      get() {
        return this.$store.getters.countDownTimer;
      },
      set(v) {
        this.$store.commit("setCountDownTimer", v);
      },
    },
    dialUsing: {
      get() {
        return this.$store.getters.dialUsing;
      },
      set(v) {
        this.$store.commit("setDialUsing", v);
      },
    },
    storeSelectList() {
      return this.$store.getters.selectedList;
    },
  },
  async created() {
    this.timer = this.countDownTimer;
    this.selectedDialer = this.dialUsing;
    this.nextCall();
    this.fetchLists(); // fetch list
  },
  methods: {
    nextCall() {
      const self = this;
      this.$http
        .get(
          `${this.$store.state.ENDPOINT}/contacts/next?listId=${this.selectedList}&dialerAction=place_call&v2=3`
        )
        .then((res) => {
          if (res.data.success) {
            self.callItem = res.data.contact;
            self.callItem.direction = "outgoing";
            if (
              typeof self.callItem.customFields === "undefined" ||
              Array.isArray(self.callItem.customFields)
            ) {
              if (typeof self.callItem.customFields === "undefined")
                self.callItem.customFields = {};
              for (var index in self.fieldList) {
                //self.callItem[self.fieldList[index].name] = ''; fieldList is about custom fields and not contact
                self.callItem.customFields[self.fieldList[index].name] = "";
              }
            }
          } else {
            self.callItem = {};
            clearInterval(self.callInterval);
          }
        })
        .catch((e) => {
          console.log(e);
          clearInterval(self.callInterval);
          self.dialState = true;
        });
    },
    startCalling() {
      const self = this;
      if (!this.dialState) {
        self.dialState = true;
        new Promise((res, rej) => {
          this.countDown = setInterval(() => {
            self.timer--;
          }, 1000);
          this.waiting = setTimeout(() => {
            self.placeCall();
            clearInterval(this.countDown);
            self.timer = self.countDownTimer;
          }, self.timer * 1000);
        });
      } else {
        clearInterval(this.countDown);
        clearTimeout(this.waiting);
        this.timer = this.countDownTimer;
        this.dialState = false;
      }
    },
    placeCall() {
      clearTimeout(this.waiting);
      var d = new Date();
      this.callStartTime = d.getTime();
      if (this.selectedDialer == "Default Dialer")
        window.open(`tel:${this.callItem.phone}`, "_blank");
      else window.open(`skype://${this.callItem.phone}?call`, "_blank");

      // console.log("refs: ",this.$refs)
      this.$refs.dialer.classList.add("dialer--after");
      this.$refs.dialer_text.classList.add("dialer--text--after");
      this.$refs.disposition.classList.add("disposition--after");
      this.$refs.dispo_text.classList.add("disposition--text--after");
    },
    async setDispo(dispo) {
      const self = this;
      var returnNextCall = true;
      // var d = new Date();
      // this.callEndTime = d.getTime();
      // var time = this.callEndTime - this.callStartTime;
      // console.log("Time: ",time);
      // this.callItem.duration = time<7200000?time:0;
      // this.callItem.timeSpentOnCall = time<7200000?time:0;
      if (dispo.title == "DNC") {
        this.callItem.dnc = true;
      }
      this.callItem.accountsId = this.$store.state.user.id;
      this.callItem.dispo = dispo.title;
      this.callItem.called = true;
      this.callItem.duration = 0;
      this.callItem.timeSpentOnCall = 0;
      this.callItem.callStart = new Date().getTime();
      this.callItem.defaultDialer = "WebDialer";
      this.callItem.calledOn = moment().format();
      try {
        var response = await disposer.setDisposition(
          this.callItem,
          returnNextCall
        );
        response = response.data;
        self.nextCallItem = response.contact;
        self.nextCallPresent = response.nextItem;
        self.callItem = self.nextCallItem;
        self.dialState = false;
        if (self.nextCallPresent) {
          self.startCalling();
        }
      } catch (e) {
        console.log("Error is setting dispo");
      }
      self.$refs.dialer.classList.remove("dialer--after");
      self.$refs.dialer_text.classList.remove("dialer--text--after");
      self.$refs.disposition.classList.remove("disposition--after");
      self.$refs.dispo_text.classList.remove("disposition--text--after");
    },
    async skipCall() {
      const self = this;
      var returnNextCall = true;
      this.callItem.accountsId = this.$store.state.user.id;
      this.callItem.dispo = "SKIP";
      this.callItem.called = true;
      this.callItem.duration = 0;
      this.callItem.timeSpentOnCall = 0;
      this.callItem.callStart = new Date().getTime();
      this.callItem.defaultDialer = "WebDialer";
      this.callItem.calledOn = moment().format();
      try {
        var response = await disposer.setDisposition(
          this.callItem,
          returnNextCall
        );
        response = response.data;
        self.nextCallItem = response.contact;
        self.nextCallPresent = response.nextItem;
        self.callItem = self.nextCallItem;
        // self.$emit('refresh');
        self.dialState = false;
      } catch (e) {
        console.log("Error is skipping");
      }
    },
    setTimer(inc) {
      if (inc) {
        this.countDownTimer++;
        this.timer++;
      } else {
        if (this.timer == 1)
          return this.$swal({
            type: "error",
            text: "Time must be greater than 1sec",
          });
        this.countDownTimer--;
        this.timer--;
      }
    },
    dialerChanged() {
      // console.log("this.selectedDialer: ",this.selectedDialer);
      this.dialUsing = this.selectedDialer;
    },
    async fetchLists() {
      var response = await this.$http.get(
        `${this.ENDPOINT}/lists/get-allowed-list`
      );
      this.lists = response.data;
      if (this.selectedList == "") {
        this.selectedList = this.lists[0].id;
      }
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}

.dialer {
  opacity: 1;
  transition: all 500ms ease-in-out;
}

.disposition {
  opacity: 0;
  height: 0px;
  overflow: hidden;
  transition: all 500ms ease-in-out;
}

.dailer--text {
  opacity: 1;
  transition: all 500ms ease-in-out;
}

.dispo--text {
  opacity: 0;
  transform: translateY(-350px);
  transition: all 500ms ease-in-out;
}

.dialer--after {
  opacity: 0;
  display: none;
  transform: translateY(-350px);
}

.disposition--after {
  opacity: 1;
  overflow: visible;
  /* transform: translateY(-420px); */
}

.dialer--text--after {
  opacity: 0;
  transform: translateY(-350px);
}

.disposition--text--after {
  opacity: 1;
  transform: translateY(0px);
}

.noPadAccordion .v-expansion-panel-content__wrap {
  padding: 0;
}

.wrapper {
  position: relative;
}

.content {
  width: 234px;
  left: -11px;
  top: 40px;
  height: 425px;
  overflow-y: auto;
}

.mobile {
  position: absolute;
  top: -10px;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgb(119, 119, 119);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #22790070;
}
</style>
